.page-actions {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @media screen and (min-width: 768px) {
    // gap: 300px;
    width: 77vw;
  }
}

.page-actions-container {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 10;
  @media screen and (min-width: 768px) {
    padding: 0 100px;
  }
}

.step-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  font-size: 14px;

  @media screen and (min-width: 768px) {
    font-size: clamp(16px, 1.111vw, 30px);
    width: 100%;
  }
}

.step-number {
  font-weight: 700;
  color: white;
}

.step-total {
  color: rgb(194, 194, 194);
}
