/* Circle nav items */
.button-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: calc(var(--circle-height) / 2);
  cursor: pointer;
  position: relative;
  padding: 20px;
}
.button-circle::before {
  content: "";
  position: absolute;
  top: -2px;
  left: -2px;
  padding: 2px;
  border-radius: 50%;
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  mask-composite: exclude;
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: exclude;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to bottom right,
    #b827fc 0%,
    #2c90fc 25%,
    #b8fd33 50%,
    #fec837 75%,
    #fd1892 100%
  );
  background-size: 300% 300%;
  -webkit-animation: moveGradient 4s alternate infinite;
  animation: moveGradient 4s alternate infinite;
}

/* determines size of buttons. It is a separate class because not all buttons are */
.button-size {
  width: 115px;
  height: 48px;
  @media screen and (min-width: 768px) {
    width: 156px;
    height: 56px;
  }
  @media screen and (max-width: 320px) {
    width: 110px;
    height: 56px;
  }
  @media screen and (max-width: 280px) {
    width: 85px;
    height: 56px;
  }
}
.button-size-large {
  width: 180px;
  height: 48px;
  margin-top: 30px;
  z-index: 20;

  @media screen and (min-width: 768px) {
    width: 186px;
    height: 56px;
  }

  .button-pill--container {
    gap: 10px !important;
  }
}

/* Pill shaped nav items */
.button-pill {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: calc(var(--circle-height) / 2);
  cursor: pointer;

  &--text {
    font-size: 14px;
    font-weight: 800;

    @media screen and (min-width: 768px) {
      font-size: 14px;
    }
  }

  &--container {
    display: flex;
    align-items: center;
    gap: 8px;
    @media screen and (min-width: 768px) {
      gap: 20px;
    }
  }
}

/* Styles for the nav images*/
.nav-image {
  width: 16px;
  height: auto;
  -webkit-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);

  @media screen and (min-width: 768px) {
    width: 30px;
    height: 30px;
  }

  &--white {
    width: 30px;
    height: 30px;
  }
}
