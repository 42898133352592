.audio-progress {
  height: 5px;
  width: 80%;
  background-color: #4f4c57;
  margin: 0 auto;
  border-radius: 3px;
  padding: 1px;
  .progress-inner {
    background-color: #252525;
    height: 3px;
    width: 0;
    text-align: left;
  }
}

.tracker-empty {
  height: 5px;
  width: 100%;
}

.player {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 30px 20px 40px;
}

.playlist-title {
  font-weight: 600;
  font-size: 18px;
  color: #fff;
  line-height: 40px;
}

.play-button img {
  width: 50px;
  height: 50px;
}

@media screen and (max-width: 768px) {
  .music-player-container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .player {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0;
    width: 100%;
    order: 1;
    margin-top: 10px;
  }
  .playlist-title {
    padding-left: 20px;
    line-height: 57px;
  }
  .audio-progress {
    order: 2;
  }
  .tracker-empty {
    order: 2;
  }
}
