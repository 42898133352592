.home-card {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
    padding-top: 50px;
  }
}

.home-card-wrapper {
  width: 100%;
  height: calc(100%);
  max-height: 100vh !important;
  max-width: 1280px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.home-card-left {
  width: 44%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.home-card-left img {
  max-width: 100%;
  @media screen and (max-width: 768px) {
    max-width: 60%;
    margin: 0 auto;
  }
}

.home-card-right {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding-bottom: 10px;
  }
}

.home-card-title {
  max-width: 540px;
  color: #fff;
  font-size: 38px;
  text-align: left;
  padding: 0 30px;
  @media screen and (max-width: 768px) {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    padding: 20px;
    font-size: 28px;
  }
  @media screen and (max-width: 480px) {
    padding: 30px;
    font-size: 20px;
  }
}

.home-card-right .gradient-border {
  width: 210px;
  padding: 0 20px;
}

.home-card-cta {
  padding-top: 20px;
  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
