/* Select Music Page  Styles */

.music-container-inner {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding: 30px;
}

.select-music-container {
  width: 100%;
  max-width: 1240px;
  max-height: calc(100vh - 183px);
  margin: 0 auto;
  margin-top: 50px;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .music-container-inner {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding: 30px 10px;
    width: 100%;
    align-items: center;
  }
}

@media screen and (max-width: 768px) {
  .music-container-inner {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px 10px;
    width: 100%;
    align-items: center;
  }

  .select-music-container {
    width: 100%;
    overflow-y: unset;
    margin: 0 auto;
    margin-top: 50px;
  }

  .select-music-container::-webkit-scrollbar {
    background: transparent; /* Optional: just make scrollbar invisible */
  }
}
