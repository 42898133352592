.rainbow {
  text-align: center;
  text-decoration: underline;
  font-size: 18px;
  letter-spacing: 5px;
}
.rainbow_text_animated {
  background: linear-gradient(
    to bottom right,
    #b827fc 0%,
    #2c90fc 25%,
    #b8fd33 50%,
    #fec837 75%,
    #fd1892 100%
  );
  // background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ff3399, #6666ff);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  animation: rainbow_animation 2s ease-in-out infinite;
  background-size: 500% 100%;
}

@keyframes rainbow_animation {
  0%,
  100% {
    background-position: 0 0;
  }
  50% {
    background-position: 100% 0;
  }
}
.video-contain {
  // position: relative;
  object-fit: contain;
}
.top-half-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 50vh;
  background: #edeced;
  z-index: -11;
}
video::-webkit-media-controls {
  display: none !important;
}
#hero-video {
  position: fixed;
  top: 50%;
  left: 50%;
  // min-width: 100%;
  // height: auto;
  z-index: -10;
  // Like setting negative margin to fix top/left settings.
  transform: translateX(-50%) translateY(-50%);
  aspect-ratio: 16/9;
  width: 100%;
  height: auto;
  // Code below will scale with width on wide screens and with height on tall screens
  // @media (max-aspect-ratio: 16/9) {
  //   width: auto;
  //   height: 100%;
  // }
  // @media (min-aspect-ratio: 16/9) {
  //   width: 100%;
  //   height: auto;
  // }
}

// .video_contain {
//   position: absolute;
//   top: -50%;
//   left: -50%;
//   width: 200%;
//   height: 200%;
// }

.home-main {
  height: 100%;

  img.tc-logo {
    height: 65px;
    @media screen and (max-width: 768px) {
      height: 40px;
    }
  }
  .first-slide-container {
    height: 100%;
  }
  .home-main-wrapper {
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    transition: 0.4s;
  }
  .home-main-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 200px;
    height: 100%;
    align-items: center;
  }
  .home-title {
    font-size: 26px;
    color: #fff;
    padding-bottom: 15px;
    margin: 0;
    font-weight: 800;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      padding-top: 20px;
    }
  }
  .home-description {
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 8px;
    color: #fffa;
    line-height: 1.5;
    font-size: 1rem;
    padding-top: 35vh;
  }
  .more-information {
    font-size: 20px;
    cursor: pointer;
    @media screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .home-cta {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 30px;
  }
  .home-cta .button-pill {
    width: 200px !important;
  }

  .home-main-image-wrapper {
    width: 60%;
    max-width: 1000px;
    height: 44vh;
    max-height: 44vh;
    margin: 0 auto;
    // border-radius: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    // padding: 0 20px;
    border-radius: 20px;

    @media screen and (max-width: 768px) {
      width: 90%;
    }
  }
  .swiper-wrapper {
    background: #fff;
  }
  .home-main-image {
    border-radius: 20px;
  }
  img.home-main-image-wrapper {
    aspect-ratio: 16/9;
    max-width: 95%;
    border-radius: 20px;
  }
  .home-description-text {
    cursor: pointer;
  }
  .btn-visible {
    display: block;
  }
  .btn-hidden {
    display: none;
  }
  .unmute-icon {
    position: absolute;
    right: 5%;
    bottom: 50px;
    width: 60px;
    height: 60px;
    background: white;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 30;
    cursor: pointer;
    @media screen and (min-width: 1580px) {
      right: calc(50vw - 690px);
    }
    @media screen and (max-width: 768px) {
      width: 40px;
      height: 40px;
    }
  }
  .unmute-icon img {
    width: 20px;
    @media screen and (min-width: 768px) {
      right: calc(50vw - 690px);
      width: 30px;
    }
  }
  .swiper-slide:last-child {
    background-image: url("https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/cf111756-84ad-48ec-9e4c-b92ca9c05e83/end-bkg.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0% 50%;
  }
  .home-end-wrapper {
    height: 100%;
    padding-bottom: 100px;
    display: flex;
    justify-content: center;
  }
  .home-end-inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding-top: 25vh;
    @media screen and (min-width: 768px) {
      padding-top: 0;
    }
  }
  .home-end-title {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    color: #fff;
    font-size: 32px;
    margin: 0;

    @media screen and (max-width: 768px) {
      font-size: 20px;
    }

    img {
      height: 25px;
      margin: 0 6px;
      @media screen and (min-width: 768px) {
        height: 40px;
        margin: 0 8px;
      }
    }
  }
  .home-end-telly {
    max-width: 600px;
    width: 40vw;
    margin: 0 auto;
  }

  @media screen and (max-width: 768px) {
    .home-end-telly {
      width: 90vw;
    }
  }
  .swiper {
    width: 100vw;
    // height: calc(100vh - 150px)
    height: 100%;
  }
  .home-header {
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 10;
    padding: 6.333vw 0 30px;
    img {
      max-width: 67.5px;
      max-height: 24.09px;
    }
    @media screen and (min-width: 768px) {
      padding-top: clamp(0.5rem, 2.5vw, 36px);
      img {
        max-width: clamp(112px, 7.778vw, 200px);
        max-height: 40px;
      }
    }
  }
  .home-header-end {
    width: 100%;
    height: 100px;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    z-index: 10;
    padding: 6.333vw 0 30px;
    transition: 0.4s;
    &.home-main-gradient {
      transition: 0.4s;
      display: none !important;
    }
    @media screen and (min-width: 768px) {
      padding-top: clamp(0.5rem, 2.5vw, 36px);
      img {
        max-width: clamp(112px, 7.778vw, 200px);
        max-height: 40px;
      }
    }
  }
  .home-header-end-inner {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 30px;
    align-items: center;
    .gradient-border {
      width: 180px;
      // padding: 0 20px;
    }
    @media screen and (max-width: 767px) {
      padding: 0 10px;
      justify-content: space-between;
      // flex-direction: column;
      // padding-top: 55px;
      align-items: center;
      .gradient-border {
        width: 180px;
        padding: 0 20px;
      }
      img {
        max-width: 150px;
        height: 30px;
      }
      .nav-image {
        display: none;
      }
      .button-size {
        height: 40px;
      }
    }
    @media screen and (max-width: 280px) {
      .gradient-border {
        width: 145px;
        height: 30px;
      }
    }
  }
  .more-about {
    padding-top: 20px;
    font-size: 0.75rem;
    font-weight: 100;
    color: white;
  }
  .more-about a {
    color: #0093bf;
  }
}

.first-screen {
  background-image: url("https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/338e851e-38af-441c-a23d-59be2117299b/firstScreen.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.second-screen {
  background-image: url("https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/cafe07b5-3b49-404a-8067-0f9c88870ac2/secondScreen.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.third-screen {
  background-image: url("https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/2b41ef93-935d-4277-8f89-4d562f4fac21/thirdScreen.png");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}
