/* MusicBox component Styles */
.music-box {
  &-container {
    position: relative;
    width: 23%;
    min-height: 300px;
    min-width: 260px;
    border-radius: 20px;
    margin-bottom: 10px;
  }

  &-inner {
    background: #252525;
    margin: 2px;
    border-radius: 20px;
  }
}
.artwork-wrapper {
  padding: 25px 10px 0 10px;

  img {
    max-width: 100%;
    width: auto !important;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .music-box {
    margin:  0 30px 20px 30px;
  }
  .music-box-container {
    position: relative;
    width: 45%;
    min-height: unset;
    min-width: unset;
    border-radius: 20px;
    margin-bottom: 30px;
    max-width: 500px;
  }

  .music-box-inner {
    display: flex;
  }

  .music-player-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .playlist-title {
    line-height: 60px;
  }

  .player {
    padding: 30px !important;
  }

  .artwork-wrapper {
    padding: 0;
    display: flex;

    img {
      max-width: 120px;
      width: 120px;
      border-radius: 10px;
      padding: 10px;
    }
  }
}

@media screen and (max-width: 767px) {
  .music-box-container {
    position: relative;
    width: 100%;
    min-height: unset;
    min-width: unset;
    border-radius: 20px;
    margin-bottom: 20px;
    max-width: 500px;
  }

  .music-box-inner {
    background: #252525;
    margin: 2px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  .artwork-wrapper {
    padding: 0;
    display: flex;

    img {
      max-width: 58px;
      width: 58px;
      border-radius: 10px;
    }
  }
}
