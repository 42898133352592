@import "../Components/imageGradient.scss";

.room-carousel-container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 40px 0 150px;
  height: 100vh;

  // CSS Grid/Flexbox size bug workaround that I found. Here is a link to the issue:
  // @see https://github.com/kenwheeler/slick/issues/982
  min-height: 0;
  min-width: 0;

  // .swiper is the outermost container for the swiper package
  .swiper {
    padding: 8px 15px;
    justify-content: center;
  }

  .swiper-slide {
    margin: auto 0;
    height: 85vw;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      object-position: center;
      filter: brightness(90%);
      border-radius: 12px;
    }

    &.swiper-slide-active {
      // Adds the gradient border properties to the active slide
      @extend .image-gradient-border;
      height: 95vw;

      img {
        filter: brightness(100%);
      }
    }
  }

  // Pads/Desktop
  @media screen and (min-width: 500px) {
    margin-top: 0;
    margin-bottom: 50px;
    .swiper {
      height: 100%;
      // Makes sure the aspect ratio doesn't get really thin at higher resolutions
      //   the vw padding will keep it scaled correctly through desktop resolutions
      padding: 8px 11.667vw;
    }

    .swiper-slide {
      // the images on the left/right of active slide will be this size
      height: 55%;

      img {
        height: 100%;
        object-position: center 43%;
        border-radius: 32px;
      }

      &.swiper-slide-active {
        // active slide will be this size
        height: 78.5%;
      }
    }
  }
}

.left-gradient {
  position: absolute;
  top: 0;
  left: 0;
  width: 15%;
  height: 100%;
  background-image: linear-gradient(
    90deg,
    rgba(26, 26, 31, 0.8) 0.22%,
    rgba(26, 26, 31, 0) 99.78%
  );
  z-index: 100;
}

.right-gradient {
  position: absolute;
  top: 0;
  right: 0;
  left: 0x;
  width: 15%;
  height: 100%;
  background-image: linear-gradient(
    to left,
    rgba(26, 26, 31, 0.8) 0.22%,
    rgba(26, 26, 31, 0) 99.78%
  );
  z-index: 10;
}

@media screen and (max-width: 768px) {
  .left-gradient,
  .right-gradient {
    display: none;
  }
}

// Navigation Arrows
$carousel-top: 60%;
.arrow-containers {
  display: none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
}

.left-arrow,
.right-arrow {
  position: absolute;
  top: $carousel-top;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 100;
}

.left-arrow {
  left: 1%;
}

.right-arrow {
  right: 1%;
}
