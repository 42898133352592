/* Adding the gradient for the Circle and Pill nav items */

.button-size {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  cursor: pointer;
}
.gradient-border-large {
  z-index: 20;
}
.gradient-border {
  --border-width: 5px;
  font-family: Lato, sans-serif;
  font-size: 2.5rem;
  color: white;
  /*  */
  transition: ease-in-out 0.2s;
  border-radius: 28px;
  background-color: rgba(34, 34, 34, 0);
  transition: background-color 0.2s;
  &:hover {
    background-color: #222;
  }
  &-no-fill {
    background-color: #222;
    transition: background-color 0.2s;
  }
  &-no-fill:hover {
    background-color: rgba(34, 34, 34, 0);
  }
  &::after {
    --border-width: 2px;
    position: absolute;
    content: "";
    top: -2px;
    left: -2px;
    z-index: -1;
    width: calc(100% + 2px * 2);
    height: calc(100% + 2px * 2);
    background: linear-gradient(
      to bottom right,
      #b827fc 0%,
      #2c90fc 25%,
      #b8fd33 50%,
      #fec837 75%,
      #fd1892 100%
    );
    background-size: 300% 300%;
    border-radius: 28px;
    -webkit-animation: moveGradient 4s alternate infinite;
    animation: moveGradient 4s alternate infinite;
  }
}

/* Adding the gradient for the Large Pill nav item */

@-webkit-keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
