.expired-room-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/cba7ccf2-86ce-44c6-b94c-ad3277ceae39/Gradient.png");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 100vh;
}
.title-expired-room {
  color: white;
  font-size: 45px;
  font-weight: 800;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 60px;
}
.telly-img-expired-room {
  position: relative;
}

.telly-img-expired-room img {
  width: 50%;
}

.button-pill-home {
  width: 100%;
  height: auto;
  padding: 0px 20px;
  background: linear-gradient(
    105.88deg,
    #ff6ae6 0%,
    #fff85c 30.73%,
    #a6ff79 67.19%,
    #5ae4ff 95.31%
  );
  border-radius: 100px;
  display: flex;
  justify-content: center;
}
.button-pill-home p {
  font-size: 16px;
  font-weight: bold;
  color: black;
}

.App-header {
  display: hidden;
}
