// * {
//   padding: 0;
//   margin: 0;
// }

$bezier: cubic-bezier(0.5, 0.05, 0.4, 0.75);
$animation-length: 1.75s;
$multiplier: 2.75;

.create-room-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #000;
}

.loader > span {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: 0 10px;
  padding: 0.5rem;
  font-weight: 800;
  background-size: 1000% 1000% !important;

  @for $i from 1 through 5 {
    &:nth-child(#{$i}) {
      background: linear-gradient(
        to bottom right,
        #b827fc 0%,
        #2c90fc #{$i * $multiplier + 5%},
        #b8fd33 #{$i * $multiplier + 30%},
        #fec837 #{$i * $multiplier + 45%},
        #fd1892 #{$i * $multiplier + 80%}
      );
    }
  }

  @media screen and (min-width: 768px) {
    width: 50px;
    height: 50px;
    margin: 0 15px;
    padding: 1rem;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

@for $i from 1 through 5 {
  .dot-#{$i} {
    animation: bounce $animation-length $bezier #{$i * 100 * 0.001}s infinite, moveGradient 7s alternate infinite;
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  20% {
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
  }

  45% {
    -webkit-transform: translate3d(0, -25px, 0);
    transform: translate3d(0, -25px, 0);
  }

  65% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

// maybe import this from gradient.scss later?
@-webkit-keyframes moveGradient {
  100% {
    background-position: 50% 50%;
  }
}

@keyframes moveGradient {
  100% {
    background-position: 50% 50%;
  }
}
