.item {
    $displacement: 30%;
    //   flex: 0 0 30%;
    transition: -webkit-transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms, -webkit-transform 300ms ease 100ms;
    margin: 0 2px;
    position: relative;
    -webkit-transform: translateX(-1 * $displacement);
    transform: translateX(-1 * $displacement);
    //   height: 30vh;
    //   width: 100%;
    img {
        // margin: auto 0;
        height: 100%;
        width: 100%;
        vertical-align: top;
        object-fit: cover;
        aspect-ratio: 2;
        border-radius: 8px;
    }
    &.active-slide {
        // max-height: 400px;
        // width: 100%;
        // -webkit-transform: scale(2.3) !important;
        // transform: scale(2.3) !important;
        img {
            height: 45vh;
            width: 76.75vw;
        }
    }
    &.active-slide~.item {
        -webkit-transform: translateX($displacement);
        transform: translateX($displacement);
    }
}

@media screen and (max-width: 768px) {
    .item {
        $displacement: 75%;
        -webkit-transform: translateX(-1 * $displacement);
        transform: translateX(-1 * $displacement);
        img {}
        &.active-slide {
            // max-height: unset;
            aspect-ratio: 0.95;
            // max-height: 360px;
            // -webkit-transform: scaleX(2) !important;
            // transform: scaleX(2) !important;
            img {
                max-height: unset;
                // max-height: 360px;
                object-fit: fill;
            }
        }
        &.active-slide~.item {
            -webkit-transform: translateX($displacement);
            transform: translateX($displacement);
        }
    }
}

.item {
    $displacement: 30%;
    //   flex: 0 0 30%;
    transition: -webkit-transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms, -webkit-transform 300ms ease 100ms;
    margin: 0 2px;
    position: relative;
    -webkit-transform: translateX(-1 * $displacement);
    transform: translateX(-1 * $displacement);
    //   height: 30vh;
    //   width: 100%;
    img {
        // margin: auto 0;
        height: 100%;
        width: 100%;
        vertical-align: top;
        object-fit: cover;
        aspect-ratio: 2;
        border-radius: 8px;
    }
    &.active-slide {
        // max-height: 400px;
        // width: 100%;
        // -webkit-transform: scale(2.3) !important;
        // transform: scale(2.3) !important;
        img {
            height: 45vh;
            width: 76.75vw;
        }
    }
    &.active-slide~.item {
        -webkit-transform: translateX($displacement);
        transform: translateX($displacement);
    }
}

@media screen and (max-width: 768px) {
    .item {
        $displacement: 75%;
        -webkit-transform: translateX(-1 * $displacement);
        transform: translateX(-1 * $displacement);
        img {}
        &.active-slide {
            // max-height: unset;
            aspect-ratio: 0.95;
            // max-height: 360px;
            // -webkit-transform: scaleX(2) !important;
            // transform: scaleX(2) !important;
            img {
                max-height: unset;
                // max-height: 360px;
                object-fit: fill;
            }
        }
        &.active-slide~.item {
            -webkit-transform: translateX($displacement);
            transform: translateX($displacement);
        }
    }
}


/*! CSS Used from: blob:http://localhost:3001/fe74c975-b4a4-4e10-a77a-07776f3e31bd */

.slide-button {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 55px;
    background: rgba(0, 0, 0, 0.5);
    border: 0;
    outline: 0;
    padding: 0;
    margin: 40px 0;
    z-index: 4;
    span {
        width: 25px;
        color: #fff;
        display: block;
        margin: 0 auto;
    }
    &--next {
        right: 0;
        span {
            -webkit-transform: rotateZ(-90deg);
            transform: rotateZ(-90deg);
        }
    }
}


/*! CSS Used from: blob:http://localhost:3001/f5d0cc8c-d39a-43de-945c-a11bdb5d377d */

.slider-wrapper {
    $carousel-top: 60%;
    padding: 200px 0;
    overflow-x: hidden;
    // overflow-y: visible !important;
    position: absolute;
    top: $carousel-top;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    @media screen and (max-width: 768px) {
        width: 100%;
        // height: 500px;
    }
}


/*! CSS Used from: blob:http://localhost:3001/92afd775-ff5d-4772-94dc-05719cece03a */

.slider {
    display: flex;
    position: relative;
    transition: -webkit-transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms, -webkit-transform 300ms ease 100ms;
}

.slider__container {
    display: flex;
    padding: 0 55px;
    transition: -webkit-transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms;
    transition: transform 300ms ease 100ms, -webkit-transform 300ms ease 100ms;
    z-index: 3;
    width: 100%;
}


/*! CSS Used from: blob:http://localhost:3001/64d8e0ab-1508-46e9-899d-bd2f78b2499f */

.show-details-button {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 300ms ease 100ms;
    background: transparent;
    border: 0;
    outline: none;
    width: 100%;
    span {
        display: block;
        width: 14px;
        margin: 0 auto;
        color: white;
    }
}

@media screen and (max-width: 768px) {
    .left-arrow,
    .right-arrow {
        -webkit-transform: translateY(300%);
        transform: translateY(300%);
    }
    .left-arrow {
        left: 1%;
    }
    .right-arrow {
        right: 1%;
    }
    .left-arrow,
    .right-arrow {
        -webkit-transform: translateY(300%);
        transform: translateY(300%);
    }
    .left-arrow {
        left: 1%;
    }
    .right-arrow {
        right: 1%;
    }
}

.swiper-slide,
.swiper-slide-active {
    transition: .3s;
}