$lightgray: #908ca0;
$darkgray: #1a1a1f;
$linearRainbow: linear-gradient(105.88deg, #ff6ae6 0%, #fff85c 30.73%, #a6ff79 67.19%, #5ae4ff 95.31%);
$linearRainbowReversed: linear-gradient(105.88deg, #5ae4ff 0%, #a6ff79 30.73%, #fff85c 67.19%, #ff6ae6 95.31%);

.enter-information {
  * {
    box-sizing: border-box;
  }

  position: relative;

  &__form {
    color: #908ca0;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    width: 420px;
    margin: 50px auto 0 auto;
    // gap: 14px;
    transition: all 0.3s;

    @media screen and (max-width: 425px) {
      // width: auto;
      // padding: 30px;
      width:100%;
    }

    label {
      padding-left: 20px;
      text-align: left;
      font-size: 14px;
      margin-top: 7px;
      margin-bottom: 7px;
    }

    .blank-field-warning {
      position: relative;
      border-radius: 100px;
      width: 100%;
      margin: auto;
      color: rgb(222, 0, 0);
      font-weight: 600;
      height: 0;
      box-sizing: border-box;
      font-size: 0;
      transition: all 0.3s;

      &--active,
      &--active-invalid-email {
        padding-bottom: 20px;
        font-size: 14px;
      }
    }

    // This corrects a browser bug where the form input would change background when autofilled
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      box-shadow: 0 0 0 30px $darkgray inset !important;
      -webkit-box-shadow: 0 0 0 30px $darkgray inset !important;
      -webkit-text-fill-color: $lightgray !important;
    }

    &__input {
      background: $darkgray;
      color: $lightgray;
      border: 1px solid $lightgray;
      border-radius: 100px;
      height: 48px;
      max-width: 420px;
      padding-left: 20px;
      font-size: 16px;
      margin: 7px;
    }
  }
}

.next-container {
  position: relative;
  padding-left: 6%;
  padding-right: 6%;

  @media screen and (min-width: 320px) {
    padding-left: 11%;
    padding-right: 11%;
  }

  @media screen and (max-width: 320px) {
    padding-left: 9%;
    padding-right: 9%;
  }
  @media screen and (max-width: 280px) {
    width: 10%;
    height: 10%;
  }
  @media screen and (min-width: 768px) {
    padding-left: 6%;
    padding-right: 6%;
  }
}

.gradient-next-border {
  --border-width: 5px;
  font-family: Lato, sans-serif;
  font-size: 2.5rem;
  color: white;
  /*  */
  transition: ease-in-out 0.2s;
  border-radius: 28px;
  background-color: rgba(34, 34, 34, 0);
  transition: background-color 0.2s;
  &::after {
    --border-width: 2px;
    position: absolute;
    content: "";
    top: -2px;
    left: -2px;
    z-index: -1;
    width: calc(100% + 2px * 2);
    height: calc(100% + 2px * 2);
    background: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
    background-size: 300% 300%;
    border-radius: 28px;
    -webkit-animation: moveGradient 4s alternate infinite;
    animation: moveGradient 4s alternate infinite;
  }
}
