%sharedFlexBox {
  display: flex;
  width: 100%;
  height: 420px;
  max-height: 100%;
  min-height: 200px;
  justify-content: center;
}

.wrapper-room-now-later {
  display: block;
}

.option-buttons {
  @extend %sharedFlexBox;
  align-items: center;
  button {
    color: white;
  }
}
.button-now,
.button-later, .button-copy {
  background: transparent;
  border: 1px solid #908ca0;
  margin: 50px;
}
.back-button {
  display: flex;
  justify-content: center;
}
.result-room-id, .room-id, .room-url, .button-copy ,.label-url, .copy-text{
  color:white;
}
.copy-text{
  background-color: transparent;
  border: none;
}
.button-copy{
  display: block;
  display: block;
  margin: 20px auto;
}
.room-info{
  @extend %sharedFlexBox;
  flex-direction: column;
  margin-top: 5px;
}
.copy-text{
  visibility: hidden;
}

.room-url, .room-id {
  overflow-wrap: break-word;
  padding: 5px 20px;
}
