.page-content-title {
  .page-title {
    padding-top: 10px;
    font-size: 24px;
    font-weight: 900;
    color: white;

    @media screen and (min-width: 768px) {
      font-size: 26px;
    }
    @media screen and (max-width: 767px) {
      font-size: 20px;
    }
  }
}
