/* Adding the gradient for the Circle and Pill nav items */

.image-gradient-border {
  img {
    width: 100%;

    @media screen and (min-width: 768px) {
      padding: 4px;
    }

  }
  &::before {
    position: absolute;
    content: "";
    top: -4px;
    left: -4px;
    z-index: -1;
    width: calc(100% + 4px * 2);
    height: calc(100% + 4px * 2);
    background: linear-gradient(to bottom right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
    background-size: 300% 300%;
    border-radius: 16px;
    -webkit-animation: moveGradient 4s alternate infinite;
    animation: moveGradient 4s alternate infinite;

    @media screen and (min-width: 768px) {
      border-radius: 32px;
    }
  }
  &::after {
    position: absolute;
    content: "";
    top: -3px;
    left: -3px;
    z-index: -1;
    width: calc(100% + 3px * 2);
    height: calc(100% + 3px * 2);
    background: #222;
    border-radius: 16px;

    @media screen and (min-width: 768px) {
      border-radius: 32px;
    }
  }
}

/* Adding the gradient for the Large Pill nav item */

@-webkit-keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}

@keyframes moveGradient {
  50% {
    background-position: 100% 50%;
  }
}
