@use "./scss/index.scss";
@import url("https://fonts.googleapis.com/css?family=Poppins:800");

* {
  box-sizing: border-box;
  font-family: Poppins;
}

body {
  background-color: #1a1a1f;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 6.333vw 0 30px;
  img {
    max-width: 67.5px;
  }
  @media screen and (min-width: 768px) {
    padding-top: clamp(0.5rem, 2.5vw, 36px);
    img {
      max-width: clamp(112px, 7.778vw, 200px);
    }
  }
}

.App-link {
  color: #61dafb;
}

a {
  text-decoration: none;
}
